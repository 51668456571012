import {Toast} from 'antd-mobile';
import React from 'react';
import {ReactNode} from 'react';
const successIcon = require('@components/assets/icons/modal/success.webp');
const warningIcon = require('@components/assets/icons/modal/warning.webp');
type ToastType = 'success' | 'warning' | 'weak';
export function showToast(
  type: ToastType,
  message: string,
  tip?: string | ReactNode,
) {
  const iconMap: Record<string, string> = {
    success: successIcon,
    warning: warningIcon,
  };
  Toast.show({
    icon: iconMap[type] ? (
      <div
        className="size-[1.75rem] bg-full"
        style={{backgroundImage: `url(${iconMap[type]})`}}
      />
    ) : undefined,
    content: (
      <div className="flex flex-col gap-2 items-center">
        <span className="text-t1 text-base font-bold">{message}</span>
        {!!tip && <span className="text-t2 text-xs">{tip}</span>}
      </div>
    ),
    maskClassName: 'toast-mask',
    maskClickable: false,
  });
}
