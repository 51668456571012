import {IUserInfo} from '@/common-pages/game-navigate';
import globalStore from '@/services/global.state';
import {useEffect, useState} from 'react';

export function useLogin() {
  const [login, setLogin] = useState(false);
  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      setLogin(!!token);
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  return login;
}

export function useBalance() {
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const amountSub = globalStore.amountChanged.subscribe(res => {
      setAmount(res.current);
    });
    return () => {
      amountSub.unsubscribe();
    };
  }, []);
  return amount;
}

export function useUserInfo() {
  const [user, setUser] = useState<IUserInfo | null>(
    globalStore.userInfo as IUserInfo,
  );
  useEffect(() => {
    const userSub = globalStore.userSubject.subscribe(res => {
      setUser(res as IUserInfo);
    });
    return () => {
      userSub.unsubscribe();
    };
  }, []);
  return user;
}
